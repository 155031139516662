.MuiStepConnector-line {
  height: 2rem;
}
.MuiStepIcon-root {
  color: #2ebb55 !important;
}
/* .MuiStepIcon-completed {
  background-color: #2ebb55 !important;
} */
.MuiStepIcon-root.MuiStepIcon-active {
  color: #2ebb55 !important;
}
.MuiStepLabel-iconContainer {
  margin-right: 0.8rem;
}
.MuiStepIcon-text {
  font-weight: bold;
}

@media (max-height: 900px) {
  .MuiStepConnector-line {
    height: 0.5rem !important;
  }
}
@media (max-height: 750px) {
  .MuiStepConnector-line {
    height: 0.5rem !important;
  }
  .MuiStepLabel-label > div {
    font-size: 1rem;
  }
}
@media (max-height: 670px) {
  .MuiStepConnector-line {
    height: 0.5rem !important;
  }
  .MuiStepLabel-label > div {
    font-size: 1rem;
  }
}

@media (max-width: 998px) {
  .MuiStepLabel-iconContainer {
    margin-right: 0rem;
  }
  .MuiStepIcon-root {
    width: 1.1rem !important;
    height: 1.1rem !important;
  }
  .MuiStepConnector-line {
    margin-left: -0.3rem !important;
    /* margin: 0.2rem 0; */
  }
}
@media (max-width: 480px) {
  .MuiStepConnector-line {
    height: 2rem;
  }
  .MuiStepLabel-iconContainer {
    margin-right: 0rem;
  }
  .MuiStepIcon-root {
    width: 0.9rem !important;
    height: 0.9rem !important;
  }
  .MuiStepper-root {
    padding: 1rem 1rem !important;
  }
} ;
