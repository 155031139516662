.verticalText {
  writing-mode: vertical-rl;
  text-orientation: downright;
  color: #afafaf;
  font-family: 'Poppins';
  font-size: medium;
  text-align: center;
  padding-right: 10px;
}

@media (max-width: 600px) {
  .verticalText {
    font-size: small;
  }
}
