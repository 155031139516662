.loader {
	width: 10%;
	height: 10%;
	align-items: center;
	justify-content: center;
	align-self: center;
}
@media only screen and (max-width: 600px) {
	.loader {
		width: 20%;
		height: 20%;
	}
}
