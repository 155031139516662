/* .Toastify__toast-body {
  width: "600px" !important;
} */
.__PrivateStripeElement {
	border: 1px solid #afafaf !important;
	padding: 1rem 1rem !important;
}
.calendly-inline-widget {
	height: 70% !important;
	width: 100%;
}
.logoHeader {
	width: 25%;
	height: auto;
	/* margin-bottom: 30px;
  margin-left: 50px; */
}
.logoSignUpHeader {
	width: 30%;
}
.mt-20 {
	margin-top: 20px;
}
[data-vl-widget='milestoneWidget'] {
	display: none !important;
}
[data-vl-widget='embedForm'] {
	width: 100% !important;
}
/* .OGcBAyJGBej5Gnyi9hGA {
  width: 100% !important;
} */
/* .adpQ3VUeQbmczOdx9ymw {
  width: 90% !important;
}
._3efP_GeH5kyBAzqnLzL {
  max-width: 100% !important;
}
._3efP_GeH5kyBAzqnLzL.cllbjvXCdYDt9A3te4cz {
  min-width: 100% !important;
}
.r-textAlign-q4m81j {
  width: 100%;
}
.r-fontWeight-16dba41 {
  width: 100%;
} */
.btn > div > div > div {
	width: min-intrinsic;
	text-align: center;
	width: -webkit-min-content;
	width: -moz-min-content;
	width: min-content;
	display: table-caption;
	display: -ms-grid;
	-ms-grid-columns: min-content;
}

.dropdown {
	position: relative;
	height: 100%;
}
.dropdownSignIn {
	position: relative;
	height: 100%;
}
.dropdown .react-tel-input {
	height: 100%;
}
.dropdownSignIn .react-tel-input {
	height: 100%;
}

.dropdown .react-tel-input .flag-dropdown {
	background-color: transparent;
	position: absolute !important;
	z-index: 1;
	width: 3rem;
	height: 100%;
	margin-left: 3px;
	margin: auto;
	align-self: center;
}
.dropdownSignIn .react-tel-input .flag-dropdown {
	background-color: transparent;
	position: absolute !important;
	z-index: 1;
	width: 3rem;
	height: 100%;
	margin-left: 3px;
	margin: auto;
	align-self: center;
}
.country-list {
	position: relative !important;
	z-index: 999;

	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.flag-dropdown.open {
	position: relative !important;

	width: 200px;
}

.flag-dropdown .selected-flag {
	width: 100% !important;
}

.dropdown > div > div {
	height: 100%;
}
.dropdownSignIn > div > div {
	height: 100%;
	border: none;
}
.dropdownSignIn > div > div {
	height: 100%;
	border: none;
}
.dropdown > div > div > input {
	font-size: 1.3rem;
}
/* .dropdown>div{
 margin-top: 0;
 height: 100%;
} */
/* ._3efP_GeH5kyBAzqnLzL._NhHeOwIn1FU09sukys_ {
  max-width: 0;
  height: 200px;
  min-width: 0;
  width: 100% !important;
} */

.containerSignup {
	width: 60%;
	background-color: '#D9D9D9';
	display: flex;
	/* background-color: #fff !important; */
	flex-direction: column;

	padding: 3rem 0;
}

.errorMessage {
	display: none;
}

.textfields {
	/* position: relative; */
	margin-top: 80px;
	margin-bottom: 30px;
}
.textfieldsSignIn {
	margin-top: 90px;
	margin-bottom: 30px;
}
.field {
	margin-top: 30px !important;
}

.outerContainer {
	margin-top: 70px;
	margin-bottom: 40px !important;
}
.signInContainer {
	/* height: 100%; */
	width: 57%;
	background-color: #000;
	padding: 40px 0;
}
.referDiv {
	padding: 3rem 0;
}
.sidebar {
	width: 43%;
}
.innersignup {
	background-image: url('../../assets/LoginScreens/signupbg.png');
	background-position: center;
	/* background-size: cover; */
	background-repeat: no-repeat;
	border-radius: 10px;
	background-size: cover;
	height: 100%;
}
.sidebar > div {
	height: 100%;
}
.sidebarSignup {
	width: 45%;
	/* height: 100%; */

	/* padding-left: 1rem;
padding-right: 1rem;
padding-top: 1rem;
padding-bottom: 1rem; */
	padding: 1% 2% 1% 2%;
	position: relative;
}
/* .sidebarSignup>div{
	height: 100%;
} */
.innersignup > div:nth-child(1) {
	border-radius: 50%;
	background-color: #000;
}
.CircularProgressbar-path {
	stroke: rgba(46, 187, 85, 1) !important;
	box-shadow: -3px 3px 7px rgba(46, 187, 85, 0.6),
		3px -3px 7px rgba(46, 187, 85, 0.6) !important;
}
.CircularProgressbar-trail {
	stroke: none !important;
	/* background-color: #000 !important; */
}
.CircularProgressbar-text {
	font-family: 'Mulish' !important;
	font-weight: bold !important;
	font-size: 22px !important;
	fill: rgba(46, 187, 85, 1) !important;
	box-shadow: -3px 3px 7px rgba(46, 187, 85, 0.6),
		3px -3px 7px rgba(46, 187, 85, 0.6) !important;
}
.sidebarCoursel {
	margin-top: 450px;
	text-align: center;
}
.stepperImg {
	margin-top: 200px;
}
.stepperImg img {
	height: 100px;
	width: 200px;
}
.otpfield input:hover {
	border: 1px solid green !important;
}
.otpfield input:focus {
	outline: 1px solid green !important;
	border: none !important;
}
.phoneinput:hover {
	border: 1px solid green !important;
}
/* .phoneinput:focus{
	border: 1px solid green !important;
} */

.previousStep {
	margin: 0 auto;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	background-color: #000;
	-webkit-text-fill-color: #afafaf;
	font-family: 'Poppins';
	-webkit-box-shadow: 0 0 0px 1000px #000 inset;
	transition: background-color 5000s ease-in-out 0s;
}

@media (max-height: 900px) {
	.signInContainer {
		padding: 30px 0;
	}
	.textfieldsSignIn {
		margin-top: 70px;
		margin-bottom: 30px;
	}
	/* .textfields {
		margin: 5px 0;
	} */
	/* .textfields>div>div>div>div {
height: 50px;
  } */
	.field {
		margin: 2px 0 !important;
	}
	/* circular progress bar */

	/* .outerContainer {
		margin-top: 70px;
		margin-bottom: 40px !important;
	} */

	.sidebarCoursel {
		margin-top: 200px;
	}
	.stepperImg {
		margin-top: 130px;
	}
}
@media (max-height: 850px) {
	/* .outerContainer {
		margin-top:55px;
		margin-bottom: 40px !important;
	} */

	.containerSignup {
		padding: 2rem 0;
	}
	.stepperImg {
		margin-top: 130px;
	}
}
@media (max-height: 780px) {
	.signInContainer {
		padding: 20px 0;
	}
	.sidebarCoursel {
		margin-top: 260px;
	}
	.textfieldsSignIn {
		margin-top: -10px;
	}
	.outerContainer {
		margin-top: 45px;
		margin-bottom: 15px !important;
	}
	.containerSignup {
		padding: 0.5rem 0;
	}
	.sidebarSignup {
		padding-top: 40px;
	}
}
@media (max-height: 720px) {
	.sidebarCoursel {
		margin-top: 110px;
	}
	.textfields {
		margin-top: 25px;
	}
	.outerContainer {
		margin-top: 45px;
		margin-bottom: 15px !important;
	}
	.containerSignup {
		padding: 0.5rem 0;
	}
	.sidebarSignup {
		padding-top: 25px;
	}
}
@media (max-height: 630px) {
	.previousStep {
		margin-top: -20px;
	}
	.outerContainer {
		margin-top: 40px;
		margin-bottom: 40px !important;
	}
	.containerSignup {
		overflow: hidden !important;

		padding: 0.5rem 0;
	}
}
@media (max-height: 580px) {
	.previousStep {
		margin-top: -30px;
	}
	.outerContainer {
		margin-top: 30px;
		margin-bottom: 40px !important;
	}
	.containerSignup {
		padding: 0.2rem 0;
	}
}
@media (max-height: 560px) {
	.previousStep {
		margin-top: -15px;
	}
	.outerContainer {
		margin-top: 15px;
		margin-bottom: 20px !important;
	}
	.containerSignup {
		padding: 0.1rem 0;
	}
}

@media (max-width: 600px) {
	.sidebar {
		display: none;
	}
	.sidebarSignup {
		display: none;
	}
	.containerSignup {
		width: 100% !important;
	}
	.signInContainer {
		width: 100%;
	}
	.dropdown > div > div > input {
		height: 58px !important;
		font-size: 1rem;
	}
	.stepperImg {
		margin-top: 80px;
	}
}

@media (max-width: 478px) {
	.accounttypesSignup {
		padding: 0 2rem;
	}
}
@media (max-width: 397px) {
	.accounttypesSignup {
		padding: 0 0.5rem;
	}
}
@media (max-width: 350px) {
	.accounttypesSignup {
		padding: 0;
	}
}

@media (max-width: 330px) {
	.button > div {
		width: 120px !important ;
	}
}
