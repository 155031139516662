
@media(min-width:992px){
    
    .forsale{
      
      width:70%
        }
        
    }
    @media(max-width:380px){
        .forsale{
            margin-top: 30px !important;
        }
    
    }
    
@media(max-width:335px){
    .forsale{
        margin-top: 60px !important;
    }

}




@media(max-width:299px){
    .forsale{
        margin-top: 100px !important;
    }

}
