/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
} */
/* html,body{

height: 100%;
} */

/*== #root{
  height: 100%;

}


#root > div {

height: 100%;
}

#root > div>div {
 
height: 100%;
  
  } */
 /* @media(max-height:600px){
  html,body{

  height: 100%;
  }

  #root {
    overflow: hidden !important;
  }

  #root > div {
    height: 100%;
  }

  #root > div > div {
    height: 100%;
  }
}
.beenish {
	background-color: aqua;
} */
 /* @media(min-height:975px){
  #root{
height: 100vh;
  }
 }
  */
  html,
body,
#root {
  height: 100%;
  min-height: 100%;
  background-color: #000;
}

#root > div {
  min-height: 100%;
}

.country_filter fieldset {
  border: 1px solid #afafaf !important;
}
.country_filter svg {
  color: #afafaf !important;
}
.apply_fitlters {
	background-color: rgb(46, 187, 85) !important;
	border: 2px solid rgb(46, 187, 85) !important;
	color: #fff !important;
	height: 100%;
}
.apply_fitlters:hover {
	background-color: transparent !important;
	color: rgb(46, 187, 85) !important;
}
.search_input{
	padding: 10px 4px !important;
	font-size: 20px !important;
}
.custom-carousel .owl-nav button {
  height: 60px;
  width: 60px;
  border-radius: 50% !important;
  background-color: transparent !important;
  top: -50% !important;
  transform: translateY(-50%) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.custom-carousel .owl-nav button img {
  height: 60px !important;
  width: 60px !important;
}
.custom-carousel img {
  width: 100%;
  height: 40rem !important;
}
.custom-carousel .owl-dots {
  position: absolute;
  left: 50%;
  top: 95%;
  transform: translate(-50%, 50%);
}

/* .country_filter .Mui-focused legend {
  padding: 0px 25px !important;
} */

@media (max-width: 768px) {
  /* .country_filter {
    margin-bottom: 10px !important;
  } */
}
