.switch {
  position: relative;
  display: inline-block;
  align-self: center;
  width: 50px;
  height: 27px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2ebb5540;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2ebb5540;
}
input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
  background-color: #2ebb55 !important;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.loaderDiv {
  align-items: center;
  justify-content: center;
  margin-top: 15%;
}
.loaderDiv > img {
  width: 100%;
}
.loaderDivBeatPack {
  align-items: center;
  justify-content: center;
  margin: auto;
}
.loaderDivBeatPack > img {
  width: 60%;
}
.duration {
  width: 50px;
  border-radius: 5px;
  text-align: center;
  font-family: 'poppins';
  color: #afafaf;
  background-color: transparent;

  font-size: 36px;
  border: 1px solid #afafaf;
}
.durationDiv {
  color: orange;
  display: flex;
}
.durationDiv > input:nth-child(1) {
  background-color: #afafaf;
}
.durationDiv > input:nth-child(3) {
  background-color: #afafaf;
}
.duration:nth-child(1) {
  margin-right: 10%;
}
.duration:nth-child(2) {
  background-color: #2ebb55;
}
.duration:nth-child(3) {
  background-color: aqua;
}
.duration:nth-child(4) {
  background-color: yellow;
  margin-right: 10%;
}
.sendCollaboratorInvite {
  cursor: pointer;
}
.tagIcon:hover svg g rect,
.deleteIcon:hover svg path,
.eyeIcon:hover svg path,
.shareIcon:hover svg g path {
  fill: #fff !important;
  /* background-color: #2ebb55; */
}
.bpmandkey:hover svg path,
.filter:hover svg path,
.pencilIcon:hover svg path {
  stroke: #fff !important;
}
.dotIcon:hover svg path,
.replyIcon:hover svg path {
  fill: #2ebb55;
}
.eyeIcon:hover .eyeCount {
  color: #fff;
}
.replyIcon {
  cursor: pointer;
}
.shareIcon {
  color: #2ebb55;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: 'poppins';
}
.shareIcon:hover,
.eyeIcon:hover {
  color: #fff;
}
.eyeIcon {
  color: #afafaf;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: 'poppins';
}
.songImg {
  height: 88px;
  width: 77px;
  /* margin-left: 5px; */
  margin-right: 5px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .songImg {
    height: 62px;
    width: 55px;
    /* margin-left: 5px; */
    margin-right: 3px;
    border-radius: 5px;
  }
}
