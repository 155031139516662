.weekFilter {
	width: 30%;
}
.customDropdown {
	border: 1px solid #afafaf !important;
	color: #afafaf;

	min-width: 150px;
}
.customDropdown > div {
	color: #afafaf;
}
.customDropdown > fieldset,
.customAutoComplete > div > div > fieldset {
	border: 0 !important;
}
.customDropdown > svg {
	color: #afafaf !important;
}
