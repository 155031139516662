.bgBlack {
	background-color: #000;
}
.rightBorder {
	border-right: 1px solid #fff;
}
.bottomBorder {
	border-bottom: 1px solid #fff;
}
.content {
	padding-left: 3%;
	padding-right: 3%;
}
.headingDiv {
	display: flex;
}
.heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 60px;
	color: #ffffff;
	text-transform: capitalize;
}
.iconParentDiv {
	display: flex;
	align-items: baseline;
	align-self: center;
	text-align: right;
	justify-content: end;
}
.iconDiv {
	border-radius: 20px;
	background: #1c1b1b;
	padding: 8px 10px;
	width: 5%;
}
.icons {
	width: 25px;
	height: 25px;
	align-self: center;
	align-items: baseline;
	cursor: pointer;
}
.backIcon {
	width: 40px;
	height: 40px;
	align-self: center;
	align-items: baseline;
	cursor: pointer;
}

.customDropdown {
	border: 1px solid #afafaf !important;
	color: #afafaf;
	background-color: #000;
	min-width: 150px;
}
.customAutoComplete {
	border: 1px solid #afafaf !important;
	color: #afafaf;
	background-color: #000;
	min-width: 180px;
	max-width: 200px;
	border-radius: 5px;
}
.customPaper > div {
	color: #afafaf;
	background-color: #1c1b1b;
}

.customPaper > div > ul > li:hover {
	background-color: #d9d9d936 !important;
}

.customPaper > div > ul > li:focus {
	background-color: #2ebb55;
}

.customPaper > div > ul > li:checked {
	background-color:#afafaf;
}

.customPaper > div > ul > li > img {
	width: 20px !important;
}
.MuiAutocomplete-root fieldset:hover {
	border-color: #f32121 !important;
}
.customAutoComplete > div > div > input,
.customAutoComplete > div > div > div > button > svg {
	color: #afafaf;
}
.customAutoComplete .Mui-focused > div > div:nth-child(2),
.customAutoComplete .Mui-focused {
	/* background-color: #000; */
	border: 1px solid #2ebb55 !important;
	outline: #2ebb55 solid 1px !important;
}
.customDropdown > div {
	color: #afafaf;
}
.customDropdown > fieldset,
.customAutoComplete > div > div > fieldset {
	border: 0 !important;
}
.searchIcon {
	color: #afafaf;
}
/* .customDropdown:focus-within > div {
	border: 3px solid red;
	background-color: #d2d4d1 !important;
	font-size: 16px;
} */
.customDropdown > svg {
	color: #afafaf !important;
}
.customTable {
	border: 0 !important;
	font-size: 20px !important;
	color: #afafaf !important;
}
.muiTable {
	padding-top: 5%;
}
.muiTable > div > div:nth-child(2) {
	background-color: #000;
	max-height: 100% !important;
}
.muiTable > div > div:nth-child(2) > table > thead > tr > th,
.muiTable > div > div:nth-child(1),
.muiTable > div > table > tfoot > tr {
	background-color: #000;
}
.muiTable
	> div
	> div:nth-child(2)
	> table
	> thead
	> tr
	> th
	> span
	> button
	> div
	> div {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	color: #afafaf;
	transform: rotate(0.24deg);
}

.muiTable > div > div:nth-child(2) > table > tbody > tr > td,
.muiTable
	> div
	> div:nth-child(2)
	> table
	> thead
	> tr
	> th
	> span
	> button
	> div {
	color: #afafaf !important;
	font-family: Poppins;
	font-size: 16px;
	border: 0;
}
.muiTable > div > table > tfoot > tr > td > div > div > div {
	color: #afafaf !important;
	font-family: Poppins;
	font-size: 16px;
	border: 0;
}
.muiTable > div > div > div > div > div > div:after {
	border-bottom: 2px solid #fff !important;
}
.muiTable > div > div > div > div > div > div > input {
	color: #fff !important;
}
.muiTable > div > div > div:nth-child(2) > button,
.muiTable > div > div > div:nth-child(2) > span > span > button,
.muiTable > div > div > div:nth-child(2) > span > button {
	color: #afafaf !important;
}
.muiTable > div > table > tfoot > tr > td {
	border: 0;
}
.filterBtn {
	background-color: #2ebb55 !important;
	border: 2px solid #2ebb55;
	width: 60px !important;
	height: 57px !important;
	margin-top: 14% !important;
	/* width: 80%; */
}
.filterBtn:hover {
	border: 2px solid #2ebb55;
	background-color: #000 !important;
}
.filterBtn > img {
	width: 50%;
}
.filterMenu > div > ul {
	background: #1c1b1b !important;
	color: #afafaf !important;
}
.songCover {
	width: 15%;
}
.flex {
	display: flex;
}
.songName {
	margin-bottom: 0;
	cursor: pointer;
}
.songCreditName {
	margin: 0;
}
.filterMenuMaskIcon {
	width: 10% !important;
}
.filterMenuIcon {
	width: 13% !important;
	margin-left: -3px;
}
.favirotes {
	width: 20px;
	height: 20px;
	margin-left: -30px;
	cursor: pointer;
}
.songData {
	display: flex;
	cursor: pointer;
}
.changeSong {
}
.loaderDiv {
	align-items: center;
	justify-content: center;
	margin-top: 15%;
	margin-left: 45%;
}
.muiTable
	> div
	> div:nth-child(2)
	> table
	> thead
	> tr
	> th
	> span
	> div
	> button
	> div
	> span
	> svg {
	color: red !important;
}
@media only screen and (max-width: 600px) {
	.heading {
		font-size: 30px;
		text-align: center;
	}
	.iconDiv {
		width: 20%;
	}
	.customDropdown,
	.customAutoComplete {
		min-width: 120px;
	}
	.muiTable
		> div
		> div:nth-child(3)
		> table
		> thead
		> tr
		> th
		> span
		> button
		> div
		> div {
		font-size: 14px;
	}
}
