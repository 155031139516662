.bgBlack {
	background-color: #000;
}

.rightBorder {
	border-right: 1px solid #fff;
}
.bottomBorder {
	border-bottom: 1px solid #fff;
}
.content {
	padding-left: 3%;
	padding-right: 3%;
}
.heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 60px;
	color: #ffffff;
}
.subHeading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: #afafaf;
}
.textarea {
	width: 100%;
	background-color: transparent !important;
	border: 1px solid #afafaf;
	border-radius: 5px;
	padding: 1%;
	color: #afafaf !important;
	font-size: 16px;
	font-family: 'poppins';
	margin-bottom: 2%;
}
.feedbackBtn {
	background-color: #2ebb55 !important;
	color: #fff !important;
	font-family: 'poppins' !important;
	font-size: 18px !important;
	border: 1px solid #2ebb55 !important;
	padding: 1% 5% !important;
	text-transform: capitalize !important;
}
.feedbackBtn:hover {
	background-color: transparent !important;
}
.errMsg {
	color: red;
	font-family: 'poppins';
	font-size: '16px';
}
@media only screen and (max-width: 600px) {
	.textarea {
		width: 97%;
	}
	.feedbackBtn {
		padding: 3% 5% !important;
	}
}
