.bannerText {
	padding-top: 80px;
	color: transparent;
	font-family: 'Arial';
	font-style: normal;
	font-weight: 700;
	font-size: 100px;
	line-height: 96px;
	text-align: center;
	-webkit-text-stroke-width: 3px;
	-webkit-text-stroke-color: white;
}
.musicImg {
	height: 80px;
	width: 80px;
	margin-top: 15px;
	margin-bottom: 15px;
	align-self: center;
}
.tabContent {
	width: 100%;
	text-align: center;
	position: absolute;
	top: 55px;
	z-index: 20;
}
.tabImg {
	justify-content: center;
	height: 75px;
	width: 75px;
}
.tabText {
	color: #fff;
	padding-top: 30px;
	font-family: 'Poppins';
	font-style: normal;
	font-size: 22px;
	font-weight: 500;
	line-height: 25px;
	text-align: center;
}
.bgImg {
	z-index: 10;
}
@media (max-width: 600px) {
	.musicImg {
		height: 60px;
		width: 60px;
		margin-top: 10px;
		margin-bottom: 10px;
		align-self: center;
	}
	.tabImg {
		height: 30px;
		width: 30px;
	}
	.tabText {
		color: #fff;
		padding-top: 10px;
		font-size: 10px;
		font-weight: 500;
		line-height: 18px;
		text-align: center;
	}
	.tabContent {
		top: 25px;
	}
	.bannerText {
		padding-top: 0px;
		color: transparent;
		font-weight: 700;
		font-size: 30px;
		line-height: 46px;
		text-align: center;
	}
}
@media (max-width: 400px) {
	.bannerText {
		padding-top: 0px;
		color: transparent;
		font-weight: 500;
		font-size: 20px;
		line-height: 26px;
		text-align: center;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: white;
	}
	.tabText {
		color: #fff;
		padding-top: 10px;
		font-size: 10px;
		font-weight: 500;
		line-height: 11px;
		text-align: center;
	}
	.featureAlignment > div {
		justify-content: center !important ;
	}
}
@media (min-width: 1800px) {
	.tabContent {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 80px;
		z-index: 20;
	}
	.musicImg {
		height: 100px;
		width: 100px;
		margin-top: 15px;
		margin-bottom: 15px;
		align-self: center;
	}
	.tabImg {
		justify-content: center;
		height: 100px;
		width: 100px;
	}
	.tabText {
		color: #fff;
		padding-top: 30px;
		font-family: 'Poppins';
		font-style: normal;
		font-size: 20px;
		font-weight: 500;
		line-height: 36px;
		text-align: center;
		padding-left: 5px;
		padding-right: 5px;
	}
}
