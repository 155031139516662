.tableContainer{
    background-color: #1C1B1B !important;
}

.tableContainer>table>thead>tr>th{
    color: #AFAFAF !important;
}

.tableContainer>table>tbody tr th,.tableContainer>table>tbody tr td{
    color: #AFAFAF !important;
}
.loaderDiv {
    width: 600px;
    display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15%;
	margin-left: 50%;
}

@media(max-width:800px){
    .checkboxCharts .MuiTypography-root{
        font-size: 0.7rem !important;
    }
}