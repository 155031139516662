.offerImg {
	height: 340px;
	width: 340px;
}
.features > div {
	gap: 10px;
}
.distributionService {
	width: 396px;
	height: 453px;
	background-image: url('../../assets/Distribution/UploadMusic.png');
	background-repeat: no-repeat;
	background-size: cover;
}
.distributionServiceTwo {
	width: 396px;
	height: 453px;
	background-image: url('../../assets/Distribution/Stream.png');
	background-repeat: no-repeat;
	background-size: cover;
}
.distributionServiceThree {
	width: 396px;
	height: 453px;
	background-image: url('../../assets/Distribution/GetPaid.png');
	background-repeat: no-repeat;
	background-size: cover;
}
.distributionService > div > div:nth-child(3),
.distributionServiceTwo > div > div:nth-child(3),
.distributionServiceThree > div > div:nth-child(3) {
	padding: 0px 15px 0px 15px;
}
.info > div {
	width: 335px;
	height: 250px;
}

@media (min-width: 1800px) {
	.features > div {
		gap: 70px;
	}
	.info > div {
		width: 406px;
		height: 292px;
	}
}
@media (max-width: 600px) {
	.offerImg {
		height: 109px;
		width: 109px;
	}
	.offerImgDiv {
		justify-content: center !important;
	}
	.distributionService {
		width: 180px;
		height: 300px;
		margin-top: 15px;
		background-image: url('../../assets/Distribution/UploadMusic.png');
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 5px;
	}
	.distributionServiceTwo {
		width: 180px;
		height: 300px;
		margin-top: 15px;
		background-image: url('../../assets/Distribution/Stream.png');
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 5px;
	}
	.distributionServiceThree {
		width: 180px;
		height: 300px;
		margin-top: 15px;
		background-image: url('../../assets/Distribution/GetPaid.png');
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 5px;
	}
	.distributionService > div > div:nth-child(3),
	.distributionServiceTwo > div > div:nth-child(3),
	.distributionServiceThree > div > div:nth-child(3) {
		padding: 5px 0px 5px 0px;
	}
	.info > div {
		width: 47%;
		height: 130px;
	}
}
