.root {
	display: flex;
	flex-direction: column;
	padding: 0 !important;
	width: 100%;
}
.dflex {
	display: flex;
}
.bg_dark {
	background-color: #000 !important;
}
.drawer {
	width: 250px;
	flex-shrink: 0 !important;
	background: #000 !important;
}
.drawerPaper {
	width: 250px;
	background: #000 !important;
	min-height: 100vh !important;
	height: 100vh !important;
	overflow-y: auto !important;
	border-right: 1px solid #afafaf !important;
}
.toolbar {
	min-height: 64px !important;
}
.content {
	flex-grow: 1;
	background-color: transparent;
	/* padding: 24px;
    margin-top: 35px; */
	/* width: 100%; */
	min-height: 100vh;
	overflow-x: hidden !important;
}
.list {
	margin: 0px 20px !important;
	padding: 0px !important;
}
.listIcon {
	color: #afafaf !important;
	padding: 0px 7px !important;
	padding-right: 10px !important;
	min-width: 0px !important;
}
.listItem {
	color: #afafaf !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	margin: 5px 0px !important;
}
.listItem:hover {
	background: #2ebb55 !important;
	color: #fff !important;
}
.listItem:hover .listIcon {
	color: #ffffff !important;
}
.listItem .listIcon svg {
	width: 1em !important;
	height: 1em !important;
	font-size: 1.7rem !important;
	display: inline-block !important;
	flex-shrink: 0 !important;
	user-select: none !important;
}
.expandMenu {
	padding-left: 20px !important;
	margin: 0px !important;
	margin-left: 5px !important;
}

.listItem:hover .stroke path {
	stroke: #fff !important;
}
.listItem:hover .fill path {
	fill: #fff !important;
}
.listItemText {
	font-size: 16px;
	font-family: 'Poppins';
}
.listItemText span {
	font-size: 16px !important;
	font-family: 'Poppins' !important;
}
.list_divider {
	border-bottom: 1px dashed #fff !important;
	margin: 10px 33px !important;
}
.moreMenu {
	margin-left: 5px !important;
	border-left: 2px solid #dfa72c !important;
	background: #000 !important;
}
.listItem.active {
	background: #2ebb55 !important;
	color: #fff !important;
	margin: 0px !important;
}
.listItem.active .listIcon {
	color: #ffffff !important;
}

.listItem.active .stroke path {
	stroke: #fff !important;
}
.listItem.active .fill path {
	fill: #fff !important;
}
.collapseMenu {
	overflow-x: hidden !important;
}
.collapseMenu ul {
	margin: 0px 5px !important;
	margin-left: 20px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	border-left: 1px dashed #fff;
}
.listSubItem {
	color: #afafaf !important;
	padding: 3px !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	padding: 5px !important;
}
.listSubItemText {
	font-size: 14px;
	font-family: 'Poppins';
}
.listSubItemText span {
	font-size: 14px !important;
	font-family: 'Poppins' !important;
}
.listSubItem:hover,
.subActive {
	background-color: #2ebb5520 !important;
	color: #2ebb55 !important;
}
.listSubItem:hover .listSubItemText {
	color: #fff !important;
}
/* .listItem .subActive{
    background: #2EBB5520 !important;
    color: #fff !important;
} */
.container {
	max-width: calc(100% - 250px) !important;
}
.iconWrapper {
	display: flex;
	gap: 10px;
	padding: 15px 30px;
}
.iconWrapper svg {
	cursor: pointer;
}
.iconWrapper svg:hover path {
	fill: #2ebb55 !important;
}
@media (min-width: 1800px) {
	.container {
		max-width: calc(100% - 250px) !important;
	}
}

@media (max-width: 600px) {
	.content {
		padding: 8px !important;
	}
	.container {
		padding: 8px !important;
	}
}
