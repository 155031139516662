.grow {
	flex-grow: 1 !important;
}
.appbar {
	background-color: #000 !important;
	border-bottom: 1px solid #afafaf !important;
}
.openDrawer {
	width: calc(100% - 300px) !important;
}
.closeDrawer {
	width: 100% !important;
}
.menuButton {
	margin-right: 15px;
}
.toolbar {
	display: flex;
	min-height: 100px;
}
.title {
	display: none;
}
.search {
	position: relative;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.15);
	margin-right: 16px;
	margin-left: 0px;
	width: 100%;
}
.search:hover {
	background-color: rgba(255, 255, 255, 0.25);
}
.searchIcon {
	padding: 0xp 16px;
	height: 100%;
	position: absolute;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.inputRoot {
	color: inherit;
}
.inputInput {
	padding: 8px 8px 8px 0px;
	padding-left: 30px;
	transition: width 0.5s;
	width: 100%;
}
.sectionDesktop {
	display: none;
}
.sectionMobile {
	display: flex;
}

.notificationIcon {
	padding: 13px;
	background-color: #1c1b1b !important;
}
.box_spacing {
	padding: 0px 16px !important;
}

@media (max-width: 767px) {
	.title {
		display: block;
	}
	.search {
		margin-left: 24px;
		width: auto;
	}
}

@media (min-width: 768px) {
	.inputInput {
		width: 20ch;
	}
	.sectionDesktop {
		display: flex;
	}
	.sectionMobile {
		display: none;
	}
}
@media (max-width: 600px) {
	.appbar {
		padding-top: 23px !important;
	}
	.notificationIcon {
		padding: 10px !important;
	}
	.notificationIcon img {
		height: 15px;
		width: 15px;
	}
	.box_spacing {
		padding: 0px 10px !important;
		padding-left: 0px !important;
	}
}
