.bgBlack {
  background-color: #000;
}

.rightBorder {
  border-right: 1px solid #fff;
}
.bottomBorder {
  border-bottom: 1px solid #fff;
}
.content {
  padding-left: 3%;
  padding-right: 3%;
}
.heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
}
.subHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #afafaf;
}
.textarea {
  width: 100%;
  background-color: transparent !important;
  border: 1px solid #afafaf;
  border-radius: 5px;
  padding: 1%;
  color: #afafaf !important;
  font-size: 16px;
  font-family: 'poppins';
  margin-bottom: 2%;
}
.feedbackBtn {
  background-color: #2ebb55 !important;
  color: #fff !important;
  font-family: 'poppins' !important;
  font-size: 18px !important;
  border: 1px solid #2ebb55 !important;
  padding: 1% 5% !important;
  text-transform: capitalize !important;
}
.feedbackBtn:hover {
  background-color: transparent !important;
}
.coverPic {
  width: 100%;
  height: 300px;
  border-radius: 5px;
}
.profilePic {
  width: 150px;
  height: 150px;
}
.loaderProfile > img {
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
.profileEditBtn {
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  color: #fff;
  position: absolute;
  top: 72%;
  margin-left: 17px;
  margin-top: -5px;
  left: 50%;
  width: 50%;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-family: 'Poppins';
}
.coverAddBtn {
  background: transparent;
  border: 2px #afafaf dashed !important;
  outline: none;
  cursor: pointer;
  border: none;
  color: #afafaf;
  font-size: 1.2rem;
  position: absolute;
  top: 50%;
  margin-left: -150px;
  margin-top: -5px;
  left: 50%;
  width: 20%;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  font-family: 'Poppins';
}
.coverEditBtn {
  background: #2ebb55;
  outline: none;
  cursor: pointer;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  top: 50%;
  margin-left: -150px;
  margin-top: -5px;
  left: 50%;
  width: 20%;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  font-family: 'Poppins';
}
@media only screen and (max-width: 600px) {
  .textarea {
    width: 97%;
  }
  .feedbackBtn {
    padding: 3% 5% !important;
  }
  .coverPic {
    width: 100% !important;
    height: 200px !important;
  }
  /* .profilePic {
		width: 100px !important;
		height: 100px !important;
	} */
  .profileEditBtn {
    margin-top: -10px !important;
  }
  .coverEditBtn {
    background: #2ebb55;
    outline: none;
    cursor: pointer;
    border: none;
    color: #fff;
    font-size: 1rem !important;
    position: absolute;
    top: 40% !important;
    margin-left: -150px;
    margin-top: -5px;
    left: 78% !important;
    width: 30% !important;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  .coverAddBtn {
    background: transparent;
    border: 2px #afafaf dashed !important;
    outline: none;
    cursor: pointer;
    border: none;
    color: #fff;
    font-size: 1rem !important;
    position: absolute;
    top: 45% !important;
    margin-left: -150px;
    margin-top: -5px;
    left: 60% !important;
    width: 60% !important;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
}
