.bgBlack {
	background-color: #000;
}
.rightBorder {
	border-right: 1px solid #fff;
}
.bottomBorder {
	border-bottom: 1px solid #fff;
}
.content {
	padding-left: 3%;
	padding-right: 3%;
}

.headingDiv {
	display: flex;
}
.heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 60px;
	color: #ffffff;
}
.backIcon {
	width: 40px;
	height: 40px;
	align-self: center;
	align-items: baseline;
	cursor: pointer;
}
.content {
	padding-left: 3%;
	padding-right: 3%;
}
.searchDiv {
	padding: 2% 0 2% 0;
	display: block !important;
}
.searchDiv > div {
	border: 1px solid #1c1b1c;
	background-color: #1b1c1c;
}
.similarArtistDiv {
	border: 1px solid #1c1b1c;
	background-color: #1b1c1c;
	color: #afafaf;
	padding: 0 1%;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	color: #afafaf;
	/* transform: rotate(0.24deg); */
}
.searchDiv > div > div:focus-within {
	outline: none !important;
}
.searchDiv > div {
	border: 1px solid #1c1b1c;
	background-color: #1c1b1c;
}
.searchDiv > div:focus-within {
	border: 1px solid #2ebb55;
}
.graphRow,
.graphRowTwo {
	justify-content: space-between;
}
.graphRowTwo {
	padding-bottom: 5%;
}
.spotifyStream {
	background-color: #1c1b1c;
	padding: 1%;
	width: 49%;
	margin-top: 2% !important;
}
.spotifyStream > div {
	display: flex;
	align-items: flex-end;
}
.spotifyStream > div > img {
	width: 5%;
	height: 5%;
	margin-right: 1%;
}
.chartHeading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: #ffffff;
	transform: rotate(0.24deg);
	margin-bottom: 0;
}
.chartSubHeading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	color: #afafaf;
	transform: rotate(0.24deg);
	margin-top: 0;
}
.loaderDiv {
	align-items: center;
	justify-content: center;
	margin-top: 15%;
	margin-left: 45%;
}
@media only screen and (max-width: 600px) {
	.searchDiv {
		width: 100%;
	}
	.heading {
		font-size: 25px;
		line-height: unset;
		/* text-align: center; */
	}
	.spotifyStream {
		width: 100%;
		margin-top: 8% !important;
		padding: 0 2% 0 2%;
	}
	.spotifyStream > div {
		display: flex;
		align-items: baseline;
	}
	.chartHeading {
		font-size: 16px;
	}
	.chartSubHeading {
		font-size: 14px;
	}
	.graphRowTwo {
		padding-bottom: 10%;
	}
}
