/* .css-view-1dbjc4n button{
    width: 30px;
background-color: transparent !important;
justify-content: center;

}

.css-view-1dbjc4n button div{
  
    height: 10px !important;
padding-top: 0;
text-align: center;
}

/* .css-view-1dbjc4n button div svg{
  
width: 10px;


} */

.signInWithBtn:hover {
	border: 1px solid #2ebb55 !important;
}
.thirdpartysignin {
	margin-top: 50px;
	text-align: center;
}

@media (max-height: 900px) {
	.thirdpartysignin {
		margin-top: 30px;
	}
}

@media (max-width: 1000px) {
	.thirdpartysignin {
		margin-top: 40px;
	}
}
