.editLabel {
	font-size: 16px;
	font-family: 'poppins';
	color: white;
	cursor: pointer;
	align-self: center;
}
.editImg {
	height: 142px;
	width: 136px;
	border-radius: 5px;
}
.coverImg {
	width: 70px;
	height: 80px;
	margin-top: 5px;
	border-radius: 5px;
}
.bpmandkey:hover svg path,
.pencilIcon:hover svg path {
	stroke: #fff !important;
}
.pencilIcon svg {
	width: 12px;
	height: 12px;
}
.bpmandkey svg,
.tagIcon svg {
	width: 14px;
	height: 14px;
}
.tagIcon:hover svg g rect {
	fill: #fff !important;
	/* background-color: #2ebb55; */
}
.errMsg {
	color: red;
	font-family: 'poppins';
	font-size: '16px';
}
@media (max-width: 600px) {
	.editLabel {
		font-size: 10px;
	}
	.editImg {
		height: 83px;
		width: 70px;
	}
	.coverImg {
		width: 50px;
		height: 60px;
		margin-top: 5px;
		border-radius: 5px;
	}
}
