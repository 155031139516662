.landingpage {
	background-color: #000;
	overflow-x: 'hidden';
}
.website-header > div > div {
	max-width: 90% !important;
}
.submitMusic {
	display: flex;
	border: 1px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 4px;
	background: #2eba55;
	color: #fff;
	font-size: 16px;
	cursor: pointer;
}

.LetsWorkTogether {
	padding-left: 3rem;
	padding-right: 3rem;
	margin: 2rem auto;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 50px;
	background: #fff;
	border: 1px solid #fff;
	border-radius: 50px;
	color: #000;
	font-size: 18px;
	cursor: pointer;
}
.LetsWorkTogether:hover {
	background: #2eba55;
	border: 1px solid #2eba55;
	color: #fff;
}
.loginHeaderBtn {
	display: flex;
	border: 1px solid #fff;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 4px;
	background: #fff;
	color: #2eba55;
	font-size: 16px;
	cursor: pointer;
	margin-right: 10px;
}
.loginHeaderBtn:hover {
	color: #2eba55;
	background-color: transparent;
}
.ApplyNowPublishing {
	display: flex;
	border: 1px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 4px;
	background: #2eba55;
	color: #fff;
	font-size: 24px;
	cursor: pointer;
	height: 80;
	width: 278;
}

.ApplyNowMembership {
	display: flex;
	border: 1px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-family: Poppins;
	border-radius: 10px;
	background: #2eba55;
	color: #fff;
	font-size: 24px;
	cursor: pointer;
	height: 80;
}
.ApplyNowWork {
	display: flex;
	border: 1px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 10px;
	background: #2eba55;
	color: #fff;
	font-size: 20px;
	cursor: pointer;
	height: 80;
}
.ApplyNowPublishingReverse {
	display: flex;
	border: 2px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 4px;
	background: transparent;
	color: #2eba55;
	font-size: 20px;
	cursor: pointer;
	height: 80;
	width: 278;
}
.ApplyNowPublishingReverse:hover {
	background: #2eba55;
	color: #fff;
}
.submitMusic-mob {
	display: flex;
	border: 1px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 4px;
	background: #2eba55;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
}
.submitMusic:hover,
.submitMusic-mob:hover,
.applyForMemebership:hover,
.applyForMemebershipFeatures:hover,
.ApplyNowPublishing:hover,
.ApplyNowMembership:hover,
.ApplyNowWork:hover {
	background: transparent;
	color: #2eba55;
}
.applyForMemebership {
	display: flex;
	justify-content: center;
	border: 1px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 4px;
	background: #2ebb55;
	width: 350px;
	color: #e3e3e3;
	font-size: 24px;
	cursor: pointer;
}
.applyForMemebershipFeatures {
	display: flex;
	text-align: center;
	justify-content: center;
	border: 1px solid #2eba55;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Poppins;
	border-radius: 4px;
	background: #2ebb55;
	width: 400px;
	color: #e3e3e3;
	font-size: 20px;
	cursor: pointer;
}
.carousel-wrap {
	margin: 90px auto;
	padding: 0 5%;
	width: 80%;
	position: relative;
}

/* fix blank or flashing items on carousel */
.owl-carousel .item {
	position: relative;
	z-index: 100;
	-webkit-backface-visibility: hidden;
}

/* end fix */
.owl-nav > div {
	margin-top: -26px;
	position: absolute;
	top: 50%;
	color: #cdcbcd;
}

.owl-nav i {
	font-size: 52px;
}
.owl-nav {
	margin-top: -26px;
	height: 100%;
	position: absolute;
	z-index: 999;
	width: 100%;
}

.owl-nav .owl-prev {
	/* left: -30px; */
	position: absolute;
	left: 40px;
	top: -50%;
	/* margin-top: -26px; */
	transform: translateY(-50%);
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
	color: #fff !important;
	font-size: 46px !important;
}
.owl-nav .owl-next {
	/* right: -30px; */

	right: 40px;
	position: absolute;
	top: -50%;
	/* background: url('./assets/member/right.png'); */
}

.card_wraper {
	width: 80%;
	align-self: center;
	margin-top: 20px;
	margin-bottom: 10px;
	display: flex;
}

@media (min-width: 1200px) and (max-width: 1400px) {
	.card_wraper {
		width: 100% !important;
		margin-top: 10px;
		margin-bottom: 5px;
	}
	.LetsWorkTogether {
		padding-left: 2rem;
		padding-right: 2rem;
		margin: 2rem auto;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-family: Poppins;
		border-radius: 4px;
		background: #fff;
		border: 1px solid #fff;
		border-radius: 50px;
		color: #000;
		font-size: 16px;
		cursor: pointer;
	}
}

@media (max-width: 1199px) {
	.card_wraper {
		width: 90% !important;
		margin-top: 10px;
		margin-bottom: 5px;
	}
}
@media only screen and (max-width: 600px) {
	.applyForMemebership,
	.applyForMemebershipFeatures {
		width: 250px;
		font-size: 16px;
	}
	.LetsWorkTogether {
		padding-left: 3rem;
		padding-right: 3rem;
		margin: 1rem auto;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-family: Poppins;
		border-radius: 50px;
		font-size: 12px;
		cursor: pointer;
	}
	.ApplyNowPublishing {
		display: flex;
		border: 1px solid #2eba55;
		padding-left: 1rem;
		padding-right: 1rem;
		margin: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-family: Poppins;
		border-radius: 4px;
		background: #2eba55;
		color: #fff;
		font-size: 14px;
		cursor: pointer;
		height: 40px;
		width: 120px;
	}
	.ApplyNowMembership,
	.ApplyNowWork {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 14px;
		border-radius: 5px;
		cursor: pointer;
	}
	.ApplyNowPublishingReverse {
		display: flex;
		border: 1px solid #2eba55;
		padding-left: 1rem;
		padding-right: 1rem;
		margin: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-family: Poppins;
		border-radius: 4px;
		background: transparent;
		color: #2eba55;
		font-size: 14px;
		cursor: pointer;
		height: 40px;
		width: 120px;
	}
	.owl-nav .owl-prev {
		top: -30%;
	}
	.owl-nav .owl-next {
		top: -50%;
	}
}
