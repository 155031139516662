.bgBlack {
  background-color: #000;
}
.talentdashboard {
  padding-left: 2%;
  padding-right: 2%;
}
.rightBorder {
  border-right: 1px solid #fff;
}
.bottomBorder {
  border-bottom: 1px solid #fff;
}
.content {
  padding-left: 3%;
  padding-right: 3%;
}
.option {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #afafaf !important;
  transform: rotate(0.24deg) !important;
  margin: 0 !important;
  min-width: max-content !important;
  text-transform: capitalize !important;
}
.option:hover {
  color: #2ebb55 !important;
}
.optionDiv {
  padding: 1% 0 0 0;
  border-bottom: 2px solid #fff;
}
.searchDiv {
  padding: 3% 0 2% 0;
  display: block !important;
}
.searchDiv > div > div:focus-within {
  outline: none !important;
  border-width: 0;
  box-shadow: none !important;
}
.searchDiv > div {
  border: 1px solid #1c1b1c;
  background-color: #1c1b1c;
}
.searchDiv > div:focus-within {
  border: 1px solid #2ebb55;
}
.loaderDiv {
  align-items: center;
  justify-content: center;
  margin-top: 15%;
  margin-left: 45%;
}

@media only screen and (max-width: 600px) {
  .optionDiv {
    text-align: center;
  }
  .optionDiv > div {
    width: 100%;
  }
  .option {
    font-size: 16px !important;
  }
  .searchDiv {
    padding: 10% 0 5% 0;
  }
  .searchDiv > div {
    z-index: 0 !important;
  }
}
/* @media (max-width: 1280px) {
  .content {
    padding-left: 2%;
    padding-right: 2%;
  }
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    min-width: 200px !important;
    /* width: 200px; */
/* padding: 0 !important;
    align-items: start !important;
    text-transform: capitalize !important;
   }
}  */

/* @media (max-width: 900px) {
  .content {
    padding-left: 2%;
    padding-right: 2%;
    justify-content: center;
  }
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    min-width: 140px !important;
    padding: 0 !important;
    align-items: start !important;
    text-transform: capitalize !important;
  }
} */

/* @media (max-width: 700px) {
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    min-width: 100px !important;
    padding: 0 !important;
    align-items: start !important;
    text-transform: capitalize !important;
  }
} */
/* @media (max-width: 500px) {
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    padding: 0 !important;
    align-items: start !important;

    min-width: 80px !important;
    text-transform: capitalize !important;
  }
} */

/* @media (max-width: 410px) {
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    padding: 0 !important;
    align-items: start !important;

    min-width: 65px !important;
    text-transform: capitalize !important;
  }
} */
