.bgBlack {
  background-color: #000;
}
.rightBorder {
  border-right: 1px solid #fff;
}
.bottomBorder {
  border-bottom: 1px solid #fff;
}
.content {
  padding-left: 3%;
  padding-right: 3%;
}
.iconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0.5rem;
  border-radius: 50%;
  margin-top: 30px;
  background-color: #1c1b1b;
}
.backIcon {
  width: 30px;
  height: 30px;
  align-self: center;
  align-items: baseline;
  cursor: pointer;
}
.headingDiv {
  display: flex;
  padding-top: 3%;
}
.icons {
  cursor: pointer;
}
.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
  text-transform: capitalize;
}
.flex {
  display: flex;
}
.songCover {
  width: 20%;
  border-radius: 12px;
}
.songName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  transform: rotate(0.24deg);
  margin-bottom: 0;
  text-transform: capitalize;
}
.creditName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #afafaf;
  transform: rotate(0.24deg);
  margin-top: 0;
  text-transform: capitalize;
}
.optionDiv {
  padding-top: 2%;
  padding-bottom: 2%;
}
.option {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #afafaf !important;
  transform: rotate(0.24deg) !important;
  margin: 0 !important;

  /* max-width: 20px !important; */
  min-width: 180px !important;
  padding: 0 !important;
  align-items: center !important;
  text-transform: capitalize !important;
}

.optionDiv .MuiTabs-indicator {
  background-color: white !important;
  width: 20px !important;
  position: relative !important;
}
.option:hover {
  color: #2ebb55 !important;
}

@media (max-width: 1280px) {
  .content {
    padding-left: 2%;
    padding-right: 2%;
  }
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    min-width: 170px !important;
    padding: 0 !important;
    align-items: center !important;
    text-transform: capitalize !important;
  }
}

@media (max-width: 900px) {
  .content {
    padding-left: 2%;
    padding-right: 2%;
    justify-content: center;
  }
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    min-width: 150px !important;
    padding: 0 !important;
    align-items: start !important;
    text-transform: capitalize !important;
  }
}
@media (max-width: 760px) {
  .content {
    padding-left: 2%;
    padding-right: 2%;
    justify-content: center;
  }
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    min-width: 120px !important;
    padding: 0 !important;
    align-items: start !important;
    text-transform: capitalize !important;
  }
}
@media (max-width: 700px) {
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    min-width: 100px !important;
    padding: 0 !important;
    align-items: start !important;
    text-transform: capitalize !important;
  }
}
@media (max-width: 500px) {
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    padding: 0 !important;
    align-items: start !important;

    min-width: 80px !important;
    text-transform: capitalize !important;
  }
  .songName {
    font-size: 1rem;
  }
  .creditName {
    font-size: 1rem;
  }
  .icons {
    width: 15px;
    height: 15px;
  }
  .iconDiv {
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 410px) {
  .option {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 30px !important;
    color: #afafaf !important;
    transform: rotate(0.24deg) !important;
    margin: 0 !important;
    padding: 0 !important;
    align-items: start !important;

    min-width: 65px !important;
    text-transform: capitalize !important;
  }
  .songName {
    font-size: 1rem;
  }
  .creditName {
    font-size: 1rem;
  }
  .icons {
    width: 15px;
    height: 15px;
  }
  .iconDiv {
    width: 22px;
    height: 22px;
  }
}
