.chooseFileButton img {
	width: 100% !important;
	height: 100% !important;
}
.cardInfo > form > div:first-child {
	border: 1px solid #afafaf !important;
	padding: 1rem 1rem !important;
	border-radius: 5px;
}
.applicationReview {
	text-align: center;
}
.applicationReview > img {
	padding: 5% 0 10% 0;
}

/* .cameraimg{
  position: absolute !important;
  top: 50% !important;
  margin-top: -15px;
  left: 70% !important;
  margin-left: -15px;
} */
/* .chooseFileButton{
    background-image:url("../../../assets/LoginScreens//uploadicon.png");
    background-size: cover;
    background-position: center;
} */
.chooseFileButton > div {
	border: none !important;
	/* margin-right: 0 !important; */
	margin: 0 auto !important;
}
.fileUploader {
	/* width: 200px !important; height: 200px !important; */
	margin: 0 auto !important;

	position: relative;
	box-shadow: none !important;
}
.chooseFileButton {
	background: none !important;
	width: 100%;
	height: 100%;
}
.imageupload {
	position: relative;
}
.imageupload > img {
	position: absolute;
	left: 75%;
	top: 80%;
	cursor: pointer;
}
.profileimg {
	position: absolute !important;
	top: 20px !important;
	left: 20px !important;
}
/* .fileContainer>img{
    position: absolute;
    top: 100%;
    left: 50%;
    background-image:url("../../../assets/LoginScreens//camera.png");
    background-size: cover;
    background-position: center;
} */
.fileContainer {
	width: 200px;
	border-radius: 50% !important;
	height: 200px;

	margin: 0 auto !important;
	padding: 0 !important;

	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.uploadPicturesWrapper {
	width: 100% !important;
	height: 100% !important;
	border-radius: 50% !important;
}
.uploadPicturesWrapper > div {
	width: 100% !important;
	height: 100% !important;
}
.fileContainer .deleteImage {
	display: none;
}
.uploadPictureContainer {
	width: 95% !important;
	height: 95% !important;
	border-radius: 50% !important;
	padding: 0 !important;
	margin: 0 !important;
	background-color: #fff !important;
}

.uploadPictureContainer img {
	width: 100% !important;
	height: 100% !important;
}
.section {
	width: 100%;
	height: 100%;
	background-color: rgb(28, 27, 27) !important;
	border-radius: 50% !important;
}
.coverPhoto {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.presentation {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.UploadIconContainer {
	width: 100%;
	height: 100%;
}
.UploadIconContainer > img {
	width: 100%;
	height: 100% !important;
	object-fit: cover;
}

.generDropdown > div > div > input:focus-within,
.generDropdown > div > div > input:hover,
.generDropdown > div > div > input:focus-visible,
.generDropdown > div > div > input:focus {
	box-shadow: #2ebb55 0px 0px 0px 0px !important;
	border-color: #2ebb55 !important;
}
.generDropdown > div > select {
	background: #1c1b1c;
	color: #afafaf;
	font-family: poppins;
	font-size: 16px;
	padding: 2px 18px;
}
.generDropdown > div > select > option:hover {
	background: #1c1b1c;
	color: #afafaf;
	font-family: poppins;
	font-size: 16px;
	padding: 2px 18px;
}
.loaderProfile > img {
	width: 100% !important;
	height: 50% !important;
}
.loaderCover > img {
	width: 60% !important;
	height: 50% !important;
}
