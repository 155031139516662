/* .chooseFileButton img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 50%;
  margin-left: -15px;
}

.fileUploader {
  width: 100% !important;
  height: 300px;
  position: absolute;
  top: 0;
  box-shadow: none !important;
  background: rgb(28, 27, 27) !important;
}
.chooseFileButton {
  background: none !important;
}

.fileContainer {
  width: 100%;

  margin: 0 !important;
  padding: 0 !important;
  background: rgb(28, 27, 27) !important;
  /* background-color: beige; */
/* box-shadow: none !important; */
/* } */
/* .fileContainer .deleteImage {
  display: none;
}  */
.section {
	width: 100%;
	height: 100%;
}
.coverPhoto {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.presentation {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.UploadIconContainer {
	width: 100%;
	height: 100%;
}
.UploadIconContainer > img {
	width: 100%;
	height: 100% !important;
	object-fit: cover;
}

.generDropdown > div > div > input:focus-within,
.generDropdown > div > div > input:hover,
.generDropdown > div > div > input:focus-visible,
.generDropdown > div > div > input:focus {
	box-shadow: #2ebb55 0px 0px 0px 0px !important;
	border-color: #2ebb55 !important;
}
.generDropdown > div > select {
	background: #1c1b1c;
	color: #afafaf;
	font-family: poppins;
	font-size: 16px;
	padding: 2px 18px;
}
.generDropdown > div > select > option:hover {
	background: #1c1b1c;
	color: #afafaf;
	font-family: poppins;
	font-size: 16px;
	padding: 2px 18px;
}
.loaderProfile > img {
	width: 100% !important;
	height: 50% !important;
}
.loaderCover > img {
	width: 60% !important;
	height: 50% !important;
}
/* .coverPhoto > div > div {
  width: 100% !important;
}
.coverPhoto > div > div > div {
  width: 100% !important;
}
.uploadPictureContainer {
  position: absolute;
  top: 0;
  width: 100% !important;
  height: 300px !important;
  padding: 0 !important;
  margin: 0 !important;
  background: rgb(28, 27, 27) !important;
}
.uploadPictureContainer img {
  width: 100% !important;
  height: 100% !important;
} */
.loaderProfile > img {
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
}
.profileEditBtn {
	background: transparent;
	cursor: pointer;
	outline: none;
	border: none;
	color: #fff;
	position: absolute;
	top: 72%;
	margin-left: 17px;
	margin-top: -5px;
	left: 50%;
	width: 50%;
	padding: 0.5rem 1rem;
	border-radius: 5px;
	font-family: 'Poppins';
}
@media only screen and (max-width: 600px) {
	.profileEditBtn {
		margin-top: -10px !important;
	}
}
