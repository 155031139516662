.heading {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 60px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 5%;
	margin-top: 3%;
}
.artistDiv {
	text-align: center;
}
.artistImageDiv {
	display: flex;
	justify-content: center;
}
.flex {
	display: flex;
}
.favirotes {
	width: 20px;
	height: 20px;
	margin-left: -30px;
	cursor: pointer;
}
.name {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	color: #ffffff;
	margin-bottom: 0;
}
.role {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	margin: 0;
	color: #afafaf;
}
.profileLink {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #2ebb55;
}
.profileImg {
	display: block !important;
	border-radius: 100px;
	width: 160px;
	height: 160px;
	margin: auto;
	padding-top: 2%;
}
@media only screen and (max-width: 600px) {
	.profileImg {
		padding-top: 20%;
	}
	.heading {
		font-size: 30px;
		text-align: center;
		margin-top: 5%;
	}
}
