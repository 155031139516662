.memberSection > div > div > div > div:nth-child(2) {
  height: 1%;
  margin-top: -300px;
}
.memberSection > div > div > div > div:nth-child(2) > button:nth-child(2) {
  top: -15px !important;
}
.bannerImg > div > img,
.bannerImg > div > div,
.bannerImg > div {
  height: calc(100vh - 64px) !important;
}
.heading {
  top: 35vh;
  /* left: 25vw; */
  position: absolute;
  text-align: center;
}

/* @media (max-width: 1280px) {
  .heading {
    top: 35vh;
    left: 15vw;
  }
} */
